<template>
    <!-- 系数维护 -->
    <div class="distribution-list page">
        <!-- <gb-table :table="table" :isShowPagination="true" @pageChange="pageChange" @sizeChange="sizeChange" /> -->
        <el-form class="page-search" :inline="true" label-width="100px">
            <div class="left">
                <!--<el-form-item label="">
                    <department
                        ref="dept"
                        v-model="searchForm.groupId"
                        apiName="getOwnDepts"
                        placeholder="组织"
                        @change="querydata"
                        :isCascader="true"
                        valueFiled="id"
                        labelFiled="groupName"
                    />
                    &lt;!&ndash; const grouo = { id: -1, title: '通用无组织' }
                data.push(grouo)
                /*item.title"
                            :value="item.id"*/&ndash;&gt;
                </el-form-item>-->
                <el-form-item>
                    <el-select
                        v-model="searchForm.groupId"
                        filterable
                        placeholder="组织"
                        @change="querydata"
                    >
                        <el-option
                            v-for="leafDept in depts"
                            :key="leafDept.id"
                            :value="leafDept.id"
                            :label="leafDept.groupName"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-select
                        v-model="searchForm.itemId"
                        clearable
                        placeholder="收支项目"
                        filterable
                        @change="querydata"
                    >
                        <el-option
                            v-for="item in budgetProjects"
                            :key="item.id"
                            :value="item.id"
                            :label="item.title"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-input
                        v-model="searchForm.batchNoLike"
                        placeholder="导入批次"
                        clearable
                        @keyup.enter.native="querydata"
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-select
                        v-model="searchForm.spuIds"
                        class="w240 search-input"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="搜索货品SKU"
                        :remote-method="querySearchSku"
                        :loading="loading"
                        multiple
                        clearable
                        collapse-tags
                        @change="querydata"
                    >
                        <el-option
                            v-for="item in searchList"
                            :key="item.id"
                            class="custom-select-item"
                            :label="item.skuTitle"
                            :value="item.spuId"
                        >
                            <span>{{ item.skuTitle }}</span>
                            <div>
                                <el-tag
                                    v-if="item.skuProperty"
                                    size="mini"
                                >
                                    规格：{{ item.skuProperty }}
                                </el-tag>
                                <el-tag
                                    v-if=" item.skuBarCode"
                                    size="mini"
                                >
                                    条码：{{ item.skuBarCode }}
                                </el-tag>
                            </div>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        @change="querydata"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button type="primary" @click="querydata">
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="handleInput">
                    <i class="iconfont icon-daoru">导入</i>
                </span>
                <span @click="updateCoefficients">
                    <i class="el-icon-document-copy">修改时间</i>
                </span>
            </div>
            <gb-table
                :table="table"
                :is-copy="true"
                :table-height="500"
                :is-show-pagination="true"
                @pageChange="pageChange"
                @sizeChange="sizeChange"
                @selection-change="handleSelectionChange"
            />
            <upload-dialog ref="uploadDialog" @initData="initData" />
            <update-coefficient ref="updateCoefficient" @initData="initData" @handle-refresh="querydata" />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import UploadDialog from './uploadDialog'
import accountingSvc from '@/services/accounting'
import { moneyFilter } from '@/utils/index'
import customerSvc from 'services/customer'
import oaSvc from 'services/oa'
import updateCoefficient from './updateCoefficient'

export default {
    name: 'Coefficient',
    components: {
        UploadDialog,
        updateCoefficient
    },
    data () {
        return {
            loading: false,
            keyword: '',
            searchList: [],
            pickerOptions: {
                // 限制预约时间
                disabledDate (time) {
                    return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
                },
                firstDayOfWeek: 1
            },
            table: {
                total: 0,
                currentPage: 1,
                pageSize: 20,
                data: [],
                firstColumn: { type: 'selection' },
                // 标题
                column: [
                    // { prop: 'budgetActualId', label: '编码', minWidth: '100' },
                    { prop: 'groupName', label: '组织', minWidth: '150', showTip: true },
                    {
                        prop: 'itemTitle',
                        label: '收支项目',
                        minWidth: '100'
                    },
                    {
                        prop: 'productTitle',
                        label: '货品',
                        minWidth: '100',
                        showTip: true
                    },
                    {
                        prop: 'skuProperty',
                        label: '规格',
                        minWidth: '100',
                        showTip: true
                    },
                    {
                        prop: 'startDate',
                        label: '开始日期',
                        minWidth: '100',
                        showTip: true,
                        formatter: val => {
                            return val.startDate
                                ? val.startDate.substring(0, 10)
                                : ''
                        }
                    },
                    {
                        prop: 'endDate',
                        label: '结束日期',
                        minWidth: '100',

                        formatter: val => {
                            return val.endDate
                                ? val.endDate.substring(0, 10)
                                : ''
                        }
                    },
                    {
                        prop: 'itemValue',
                        label: '系数',
                        minWidth: '100',
                        formatter: row => {
                            return this.moneyFilter(row.itemValue)
                        }
                    },
                    { prop: 'creator', label: '导入人员', minWidth: '100' },
                    { prop: 'batchNo', label: '导入批次', minWidth: '100' },
                    { prop: 'createTime', label: '导入时间', minWidth: '100' }
                ],
                operator: [
                    {
                        text: '删除',
                        fun: this.handleDel
                    }
                ],
                operatorConfig: {}
            },
            // 查询条件数据
            searchForm: {},
            dateRange: ['', ''],
            // 导入弹框
            dialogVisible: false,
            budgetProjects: [],
            depts: [],
            coefficientList: [],
            formLabelWidth: '120px'
        }
    },
    created () {
        this.initData()
        this.getBudgetProjects()
        this.getLeafDepts()
    },

    methods: {
        handleSelectionChange (val) {
            this.coefficientList = val
        },
        handleOption (event, val) {
            // 渠道id
            const target = _.cloneDeep(val)
            const list = this.form.sellSkuList
            if (list.find(item => [item.productSkuId, item.id].includes(target.id))) {
                this.$message('已经添加过')
                return
            }
            // // target.relationName = target.channelName
            // target.gId = target.id
            // delete target.id // 修改不需要这个,这个其实是这条渠道的id --mark-1
            // delete target.version // 删除不需要这个 --mark-1
            target.skuAlias = target.skuTitle + (target.skuProperty === '默认规格' ? '' : target.skuProperty)
            // target.version = null
            this.form.sellSkuList.push(target)
        },
        moneyFilter,
        async querySearchSku (queryString) {
            // 快速增加sku
            if (!queryString) {
                return
            }
            this.loading = true
            // 远程搜索
            const query = {
                enabled: true, // 启用状态
                noOrTitleLike: queryString, // 渠道名称
                current: 1,
                size: 10
            }
            const { data = [], total = 0 } = await customerSvc.getSkulistPage(query)
            this.loading = false
            this.searchList = data
        },
        async initData () {
            const [startDate, endDate] = this.dateRange
            const param = Object.assign(
                {},
                {
                    size: this.table.pageSize,
                    current: this.table.currentPage,
                    startDate,
                    endDate
                },
                this.searchForm
            )
            const res = await accountingSvc.coefficientPage(param)
            this.table.data = res.data || []
            this.table.total = res.total
        },
        // 系数维护删除
        handleDel (val) {
            this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    await accountingSvc.coefficientDelete([val], 'json')
                    this.initData()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        /* 获取收支设置 */
        async getBudgetProjects () {
            const { data = [] } = await accountingSvc.getBudgetListCommon()
            this.budgetProjects = data
        },
        /* 获取当前用户所属员工所在组织及其所有子组织 */
        async getLeafDepts () {
            const grouo = { id: -1, groupName: '通用无组织' }
            this.depts.push(grouo)
            const { data = [] } = await oaSvc.getOwnDepts()
            // this.depts = data.filter(item => item.leaf)
            data.filter(item => item.leaf).forEach((column, index) => {
                this.depts.push(column)
            })
        },
        // 获取列表数据的唯一标识
        getRowKey (row) {
            return row.id
        },
        pageChange (val) {
            this.table.currentPage = val
            this.initData()
        },
        sizeChange (val) {
            this.table.currentPage = 1
            this.table.pageSize = val
            this.initData()
        },
        /* 查询第几页数据 */
        handleCurrentChange (val) {
            this.searchForm.pageIndex = val
            this.initData(this.searchForm)
        },
        handlesSizeChange (val) {
            this.currentPage = 1
            this.searchForm.pageIndex = 1
            this.searchForm.pageSize = val
            this.initData(this.searchForm)
        },
        querydata () {
            this.table.currentPage = 1
            this.initData()
        },
        contentChange () {
            this.conceal = !this.conceal
        },
        reset () {
            this.searchForm = {}
            // this.$refs.dept.cascaderValue = ''
            this.table.currentPage = 1
            this.dateRange = ['', '']
            this.keyword = ''
            this.searchList = []
            this.initData()
        },
        // 点击导入按钮
        handleInput () {
            this.$refs.uploadDialog.dialogVisible = true
        },
        updateCoefficients () {
            if (this.coefficientList.length <= 0) {
                this.$message.warning('请选择数据！')
                return false
            }
            const _ref = this.$refs.updateCoefficient
            _ref.visible = true
            _ref.coefficientList = this.coefficientList
        }
    }
}
</script>
<style lang="scss" scope>
    .custom-select-item {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 50px;
        line-height: 50px;

        & > div,
        & > span {
            line-height: 1;
        }

        & > div {
            font-size: 12px;
            color: #b4b4b4;

            .el-tag {
                &:not(:first-of-type) {
                    margin-left: 10px;
                }
                color: #b4b4b4;
            }
        }
    }

</style>
