<template>
    <el-dialog
        class="updateCoefficient"
        width="600px"
        top="25vh"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        :close-on-click-modal="false"
    >
        <el-form
            ref="form"
            :model="form"
            :show-message="false"
            label-width="120px"
            inline
        >

            <el-form-item label="时间区间" required>
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :clearable="true"
                >
                </el-date-picker>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button
                type="primary"
                @click="handleConfirm"
            >保存</el-button
            >
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import accountSvc from 'services/accounting'

    export default {
        name: '',
        components: {},
        data () {
            return {
                visible: false,
                title: '修改时间',
                form: {
                    from: '',
                    to: ''
                },
                coefficientList: [],
                loading: false,
                selectedObjs: [],
                dateRange: ['', ''],
                pickerOptions: {
                    // 限制预约时间
                    disabledDate (time) {
                        return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
                    },
                    firstDayOfWeek: 1
                }
            }
        },
        created () {
        },
        methods: {
            handleCancel () {
                this.visible = false
                this.dateRange = ['', '']
            },
            handleConfirm () {
                this.$refs['form'].validate(async (valid) => {
                    console.log(valid)
                    if (valid) {
                        try {
                            const [startDate, endDate] = this.dateRange
                            const coefficientList = this.coefficientList

                            if (startDate.length <= 0 || endDate.length <= 0) {
                                this.$message.warning('请选择时间区间！')
                                return false
                            }
                            if (coefficientList.length <= 0) {
                                this.$message.warning('请选择数据！')
                                return false
                            }
                            const param = {
                                coefficientList,
                                startDate,
                                endDate
                            }
                            await accountSvc.coefficientUpdate(param)
                            this.$message({
                                type: 'success',
                                message: '修改成功!'
                            })
                            this.visible = false
                            this.$emit('handle-refresh')
                            this.$message.success('保存成功！')
                        } catch (error) {
                            console.log(error)
                        }
                    } else {
                        this.$message.warning('请完善表单内容！')
                        return false
                    }
                })
            },
        }
    }
</script>
<style lang="scss" scoped>
    .el-dialog {
        .el-row {
            line-height: 44px;
        }

        .el-select {
            width: 60%;
        }
    }
</style>
